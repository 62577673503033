import { Checkbox } from 'antd/lib'
import styles from './CameraList.module.css'
import { IconNames } from 'components/UI/Icon/icons'
import { DeviceDTO } from 'api/api_code'
import Icon from '@ant-design/icons'
import useTranslation from 'next-translate/useTranslation'

type CameraListFooterProps = {
  selectedRowKeys: string[]
  handleSelectAll: () => void
  handleUpgrade: () => void
  handleSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>
  data: DeviceDTO[]
}

const CameraListFooter = ({
  selectedRowKeys,
  handleSelectAll,
  handleUpgrade,
  handleSelectedKeys,
  data,
}: CameraListFooterProps) => {
  const { t } = useTranslation('cameras')
  return (
    <div style={{ display: 'flex' }}>
      <Checkbox
        className={styles.tableSelectAll}
        style={{ marginRight: '35px', display: 'flex', alignItems: 'center' }}
        checked={Boolean(selectedRowKeys.length)}
        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
        onClick={handleSelectAll}
      >
        {t('tableActions.select')}
      </Checkbox>
      {Boolean(selectedRowKeys.length) && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4>{t('tableActions.selection', { cams: selectedRowKeys.length })}</h4>
          <button className={`${styles.tableFooterAction} ml-3`} onClick={handleUpgrade}>
            {t('tableActions.upgrade')} <Icon component={IconNames.DOWNLOAD} size={14} />
          </button>

          <button
            className={`${styles.tableFooterAction} ml-2`}
            onClick={() => handleSelectedKeys([])}
          >
            {t('tableActions.clear')} <Icon component={IconNames.CROSS} size={14} />
          </button>
        </div>
      )}
    </div>
  )
}
export default CameraListFooter
